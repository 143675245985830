<template>
    <div
        class="accordion-panel"
        :class="{
            'accordion-panel--active': contentVisible,
        }"
    >
        <div class="accordion-panel__headline" @click="togglePanel">
            <slot name="headline" />
            <slot name="icon" />
        </div>
        <expand-transition>
            <div v-if="contentVisible" class="accordion-panel__content">
                <slot />
            </div>
        </expand-transition>
    </div>
</template>

<script>
export default {
    name: "AccordionPanel",

    emits: ["changed"],

    props: {
        open: Boolean,
        small: Boolean,
    },

    data() {
        return {
            contentVisible: false,
        };
    },

    computed: {
        icon() {
            return this.small ? "arrow-down" : "plus";
        },
    },

    watch: {
        open(newValue, oldValue) {
            this.contentVisible = newValue;
        },
    },

    created() {
        this.contentVisible = this.open;
    },

    mounted() {},

    methods: {
        togglePanel() {
            this.contentVisible = !this.contentVisible;
            this.$emit("changed", { open: this.contentVisible, panel: this });
        },

        closePanel() {
            this.contentVisible = false;
        },

        openPanel() {
            this.contentVisible = true;
        },
    },
};
</script>
