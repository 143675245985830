import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const SCRUB = true;

export const init = () => {
    const sections = gsap.utils.toArray(".section-zoom-media");

    sections.forEach((item) => {
        const contentAreas = gsap.utils.toArray(
            item.querySelectorAll(".section-zoom-media__content-area")
        );
        const mediaArea = item.querySelector(".section-zoom-media__media-area");

        contentAreas.forEach((contentArea, index) => {
            const textElement = contentArea.querySelector(".text");

            gsap.fromTo(
                contentArea,
                {
                    opacity: 0,
                    scale: 0.8,
                },
                {
                    scrollTrigger: {
                        trigger: textElement,
                        scrub: SCRUB,
                        start: "top bottom",
                        end: "bottom center",
                        invalidateOnRefresh: true,
                    },
                    opacity: 1,
                    scale: 1,
                    duration: 1.25,
                }
            );

            // gsap.to(mediaArea, {
            //     scrollTrigger: {
            //         trigger: contentArea,
            //         scrub: SCRUB,
            //         start: "bottom center",
            //         end: "bottom top",
            //         invalidateOnRefresh: true,
            //         // markers: true,
            //         toggleActions: "play complete reverse reset",
            //     },
            //     autoAlpha: 0,
            //     duration: 1.25,
            // });
        });

        gsap.fromTo(
            mediaArea.querySelector(".media__media-element"),
            {
                scale: 0.7,
            },
            {
                scrollTrigger: {
                    trigger: item.querySelector(
                        ".section-zoom-media__content-areas"
                    ),
                    scrub: SCRUB,
                    start: "top bottom",
                    end: "bottom center",
                    invalidateOnRefresh: true,
                    toggleActions: "play complete reverse reset",
                },
                ease: "linear",
                scale: 2.2,
                duration: contentAreas.length,
            }
        );
    });
};

export default init;
