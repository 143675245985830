<template>
    <swiper
        class="stage-slider"
        :modules="modules"
        :autoplay="{
            delay: autoplayDuration,
            disableOnInteraction: false,
        }"
        :pagination="{
            el: '.slideshow-pagination',
            clickable: true,
        }"
        direction="horizontal"
        parallax
        :breakpoints="{
            768: {
                direction: 'vertical',
            },
        }"
        @slideChange="handleSlideChange"
    >
        <slot />

        <template #container-end>
            <slot name="pagination" />
        </template>
    </swiper>
</template>

<script>
import { Autoplay, Pagination, Parallax } from "swiper";

export default {
    name: "StageSlider",

    props: {
        hasMultipleChildren: {
            type: Boolean,
            default: false,
        },
        autoplayDuration: {
            type: Number,
            default: 10000,
        },
    },

    data: () => {
        return {
            modules: [Autoplay, Pagination, Parallax],
            swiper: null,
        };
    },

    methods: {
        handleSlideChange(swiper) {
            const prevSlide = swiper.slides[swiper.previousIndex];
            const currentSlide = swiper.slides[swiper.realIndex];

            const prevVideos = prevSlide.querySelectorAll("video");
            const currentVideos = currentSlide.querySelectorAll("video");

            if (prevVideos.length > 0) {
                prevVideos.forEach((video) => video.pause());
            }

            if (currentVideos.length > 0) {
                currentVideos.forEach((video) => {
                    video.pause();
                    video.currentTime = 0;
                    video.load();
                });
            }
        },
    },
};
</script>
