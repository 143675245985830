<template>
    <div class="accordion-panels" :class="{ 'accordion-panels--small': small }">
        <template v-for="(comp, index) in $slots.default()" :key="index">
            <component :is="comp" @changed="handlePanelChange" ref="panel" />
        </template>
    </div>
</template>

<script>
export default {
    name: "AccordionContainer",

    props: {
        small: Boolean,
    },

    data() {
        return {
            panels: [],
            activeIndex: -1,
        };
    },

    computed: {},

    async created() {
        this.$slots.default().forEach((panel, index) => {
            if (panel.open) {
                this.activeIndex = index;
            }
        });
    },

    methods: {
        closeAll() {
            this.$refs["panel"].forEach((panel, index) => {
                panel.closePanel();
            });
        },

        handlePanelChange(event) {
            this.closeAll();

            if (event.open) {
                event.panel.openPanel();
            }
        },
    },
};
</script>
